import React from 'react';
import SEO from '../../components/seo';
import CategoryPage from '../../components/desktop/Categories/CategoryPage';
import Family from '../../components/desktop/Family/Family';

const Artist = () => {
  return (
    <>
      <SEO title={'Rodzina'} />
      <Family />
    </>
  );
};

export default Artist;
