import React from 'react';
import {
  ColorWrapper,
  Container,
  ImageMove,
  PageContainer,
  PhotoSign,
  TextSpaceMargin,
} from '../AuthorPage/styles';
import {GalleryAsyncGrid, PhotoWithSign} from './styles';
import {SectionText, SectionTitle} from '../../atoms';
import img0 from './images/00.png';
import img1 from './images/1.jpg';
import img2 from './images/2.jpg';
import img3 from './images/3.jpg';
import img4 from './images/4.jpg';
import img5 from './images/5.jpg';
import img6 from './images/6.jpg';
import img7 from './images/7.jpg';
import img8 from './images/8.jpg';
import img9 from './images/9.jpg';
import img10 from './images/10.jpg';
import img11 from './images/11.jpg';
import img12 from './images/12.jpg';
import img13 from './images/13.jpg';
import img14 from './images/14.jpg';
import img15 from './images/15.jpg';
import img16 from './images/16.jpg';
import img17 from './images/17.jpg';
import img18 from './images/18.jpg';
import img19 from './images/19.jpg';
import img20 from './images/20.jpg';
import img21 from './images/21.jpg';
import img22 from './images/22.png';
import img23 from './images/23.png';
import img24 from './images/24.png';
import img25 from './images/25.jpg';
import img26 from './images/26.png';
import img27 from './images/27.png';
import img28 from './images/28.png';
import img29 from './images/29.jpg';
import img30 from './images/30.jpg';
import img31 from './images/31.jpg';
import img32 from './images/32.jpg';
import img33 from './images/33.jpg';
import Gallery from '../Gallery/Gallery';
import PlayerGrey from '../../atoms/PlayerGrey/PlayerGrey';

const Family = () => {
  return (
    <PageContainer backgroundColor={'#e09087'} style={{paddingBottom: 0}}>
      <Container>
        <ImageMove src={img0} />
        <TextSpaceMargin>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}>
            <SectionTitle color={'#525252'}>RODZINA</SectionTitle>
            <PlayerGrey src={require('../../../audio/RODZINA.mp3')} />
          </div>
          <SectionText color={'#525252'} style={{paddingTop: '1.5rem'}}>
            &emsp;&emsp;Dla Jana Szczepkowskiego rodzina była bardzo ważna. Jego
            przywiązanie szczególnie widać w&nbsp;relacji z&nbsp;matką Józefą
            Szczepkowską z&nbsp;Kuczyńskich (1840-1891, córka Jana i&nbsp;Marii
            z&nbsp;domu Katańskiej). Matka artysty zmarła w&nbsp;1891 roku,
            kiedy przyszły rzeźbiarz miał zaledwie 13 lat. Szczepkowski
            wspominał o&nbsp;niej i&nbsp;jej rodzinie już na samym początku
            swoich wspomnień “Wypukłe i&nbsp;wklęsłe”: „Matkę ubóstwiałem.
            Wychowana była we Lwowie w&nbsp;latach powstania styczniowego 1863
            roku. Jako 13-letnia panienka brała udział w organizacji
            powstaniowej, która ukrywała powstańców i&nbsp;opiekowała się nimi.
            (...) Dziadek Jan Kuczyński był sławnym w&nbsp;owych czasach
            lutnistą, akustykiem kształconym we Włoszech, zamożnym właścicielem
            wsi Hołosko pod Lwowem i&nbsp;kilku kamienic we Lwowie. W&nbsp;domu
            Kuczyńskich panowała atmosfera popowstaniowa, atmosfera romantyzmu
            lat niewoli (...). Widzę Ciebie droga Matko, jak nad łóżeczkiem moim
            [pochylasz się], w&nbsp;anielskim uśmiechu pełnym łez spływających
            po Twojej pięknej twarzy (...).”
            <br />
            <br />
            &emsp;&emsp;Swojej zmarłej przedwcześnie matce Szczepkowski
            zawdzięcza to, kim się stał. Ojciec artysty życzył sobie, aby
            Szczepkowski zdobywał wykształcenie ściśle techniczne, lecz za
            namową matki zezwolił na naukę rysunku i&nbsp;rzeźby.
            Z&nbsp;wyjazdem do Szkoły Przemysłu Drzewnego w Zakopanem wiążę się
            kolejne ciężkie przeżycie artysty, które boleśnie rozpamiętywał
            u&nbsp;schyłku swojego życia we wspomnieniach “Wypukłe
            i&nbsp;wklęsłe” opracowanych przez Katarzynę Chrudzimską- Uhera.
            Rzeźbiarz wspomina: “ Rodzice przystąpili do przygotowania mojej
            wyprawy do szkoły (...). Był to jeden z&nbsp;najboleśniejszych dni
            w&nbsp;moim życiu. Na dłuższy okres rozstawałem się z&nbsp;domem.
            Bolesne było rozstanie ze święta Matką moją, jakby
            w&nbsp;przeczuciu, że żegnamy się na zawsze, że Jej więcej nie
            zobaczę”.
            <br />
            <br />
            &emsp;&emsp;Ojciec Jana Szczepkowskiego, Alojzy Szczepkowski (1839 -
            1916, syn Józefa i&nbsp;Marii z&nbsp;d. Ruczka) był urzędnikiem C.K.
            Kolei Żelaznych. Alojzy Szczepkowski oprócz Jana doczekał razem
            z&nbsp;Józefą jeszcze dwóch synów: Kazimierza (1874-1945)
            i&nbsp;Michała (1885-1976).
            <br />
            <br />
            &emsp;&emsp;W rodzinie Jana Szczepkowskiego „gen artystyczny”
            zaakcentował się bardzo mocno. Widać to po “gałęzi” Michała
            Szczepkowskiego. Michał po ślubie z&nbsp;Heleną Recht (1893-1955)
            doczekał się dwójki dzieci: Ireny (1921-1971) oraz Andrzeja
            (1923-1997). Andrzej Szczepkowski był znanym polskim aktorem,
            podobnie jak jego córka Joanna Szczepkowska (ur. 1953). Popularna
            aktorka zapisała się również w&nbsp;pamięci potomnych słynnym
            zdaniem wygłoszonym w&nbsp;Dzienniku Telewizyjnym: “Proszę Państwa,
            4 czerwca 1989 roku skończył się w Polsce komunizm”. Joanna po
            ślubie z&nbsp;aktorem Mirosławem Konarowskim (ur. 1952) doczekała
            się dwóch córek, Marii Konarowskiej (ur. 1980) oraz Hanny
            Konarowskiej (ur. 1983), które również zostały aktorkami.
            Z&nbsp;kolei Andrzej Szczepkowski po ślubie z&nbsp;Romą Parandowską
            (1927-2007) został zięciem autora Mitologii, dwukrotnie nominowanego
            do literackiej Nagrody Nobla Jana Parandowskiego (1895-1978).
          </SectionText>
        </TextSpaceMargin>
      </Container>
      <ColorWrapper
        bgColor={'#525252'}
        style={{marginTop: '3rem', paddingBottom: '2rem'}}>
        <Container>
          <Gallery
            maxWidth={60}
            columnsNumber={2}
            isImgFit
            hoverColor="rgba(224, 144, 135, 0.4)"
            colorSign="#fbfbfb"
            images={[
              {src: img1, sign: 'Maria i Jan w 1917 r.'},
              {
                src: img2,
                sign:
                  'Jan Szczepkowski z żoną Marią przed lotem do Gdyni, 1937',
              },
            ]}
          />

          <SectionText color={'#fbfbfb'}>
            &emsp;&emsp;Jan Szczepkowski ożenił się 18 maja 1913 z&nbsp;Marią
            z&nbsp;Morozowiczów (1885 - 1968). Ślub odbył się w&nbsp;kościele
            bernardynów w&nbsp;Krakowie. Świadkami na ślubie byli rzeźbiarz
            Xawery Dunikowski i&nbsp;prawnik Albin Chęcińśki. Maria Morozowicz
            była aktorką i&nbsp;pisarką. Po ukończeniu pensji N. Porazińskiej
            w&nbsp;Warszawie została wolną słuchaczką Uniwersytetu
            Jagiellońskiego w&nbsp;Krakowie. To właśnie w&nbsp;Krakowie poznała
            i&nbsp;poślubiła Jana. Wcześniej, zdążyła mieć za sobą udaną karierę
            aktorską. Od 1902 występowała w&nbsp;zespole Koła Miłośników Sceny
            w&nbsp;sali Elizeum przy ul. Karowej w&nbsp;Warszawie. Grała
            w&nbsp;Teatrze Letnim, Teatrze Nowości i&nbsp;Teatrze Polskim
            w&nbsp;Wilnie. W&nbsp;okresie wileńskim działała w&nbsp;grupie
            artystyczno-literackiej „Banda”. W&nbsp;tym czasie (1910 r.)
            debiutowała swoimi utworami poetyckimi w wydawnictwie “Żurawce”.
            Scenę opuściła w&nbsp;roku 1913, kiedy po ślubie poświęciła się
            karierze literackiej. Jeden z&nbsp;nielicznych wyjątków zrobiła,
            wracając na scenę Teatru Letniego w&nbsp;Milanówku
            z&nbsp;prapremierą sztuki Nowa kobieta (1929), którą wyreżyserowała
            i&nbsp;gdzie zagrała główną rolę.
            <br />
            <br />
            &emsp;&emsp;W 1914 r. jej farsa “Korepetytor” uzyskała II nagrodę w
            konkursie Teatru Nowoczesnego w&nbsp;Warszawie. Kiedy zamieszkała po
            I&nbsp;Wojnie Światowej w&nbsp;Milanówku, w&nbsp;willi “Waleria”
            napisała swoje najbardziej znane utwory sceniczne: Maniuś (prem.
            1922), Sprawa Moniki (1932) – tłumaczona na kilka języków,
            sfilmowana w&nbsp;USA, Milcząca siła (1933), Nowa kobieta (1934),
            Typ A&nbsp;(1934), Walący się dom (1937). Była autorką scenariuszy
            filmowych: Melodie duszy (1918), Sezonowa miłość (wg G. Zapolskiej,
            1918), Krysta (1919), Wyrok życia (1933), oraz m.in. powieści Twarz
            w&nbsp;lustrze (1935). W 1968 w&nbsp;Warszawie ukazały się jej
            wspomnienia “Z lotu ptaka”.
          </SectionText>
        </Container>
      </ColorWrapper>
      <ColorWrapper bgColor={'#e09087'} style={{paddingBottom: '2rem'}}>
        <Container>
          <Gallery
            columnsNumber={2}
            maxWidth="60"
            isImgFit
            hoverColor="rgba(224, 144, 135, 0.4)"
            colorSign="#525252"
            images={[
              {
                src: img3,
                sign: 'Waleria wraz z&nbsp;córką Marią w&nbsp;ogrodzie',
              },
              {
                src: img10,
                sign:
                  'Waleria w oczekiwaniu na pociąg na przystanku kolejowym w Milanówku',
              },
            ]}
          />
          <SectionText color={'#525252'}>
            &emsp;&emsp;Maria pochodziła z&nbsp;artystycznej rodziny. Jej
            rodzice Waleria z&nbsp;Kotowiczów (1862-1931, córka Ksawerego
            i&nbsp;Róży) oraz Rufin Morozowicz (1848-1931, syn Rufina
            i&nbsp;Katarzyny) byli artystami scenicznymi. Pobrali się
            w&nbsp;1885 r. Dla Rufina był to drugi ślub, bo w&nbsp;1875 r. wziął
            ślub z&nbsp;aktorką Rozalią Kozakowską (1851-1883), z&nbsp;którą
            doczekał się dwoje dzieci (przyrodnie rodzeństwo Marii), które także
            zostały aktorami: córki Karoliny (1867-1952, po mężu Schulz) oraz
            syna Leopolda (1876-1945). <br />
            <br />
            &emsp;&emsp;Waleria Morozowicz z&nbsp;Kotowiczów była teściową
            Szczepkowskiego i&nbsp;artystką obdarzoną najwyższym kobiecym głosem
            — sopranem koloraturowym. Po ślubie z&nbsp;Rufinem zgodnie
            z&nbsp;przyjętymi ówcześnie obyczajami zajęła się prowadzeniem domu
            i&nbsp;życiem „przy mężu”. Po nagłej śmierci w&nbsp;wyniku
            zapadnięcia na szkarlatynę ich młodszego dziecka Jerzego zapadła na
            długą chorobę (egzemę), a&nbsp;wyzdrowiawszy, podjęła pracę zawodową
            w&nbsp;firmie założonej przez męża. Jej mąż pomimo wielu pomysłów
            nie miał szczęścia w&nbsp;interesach, a&nbsp;jeden z&nbsp;nich –
            produkcję win – przypłacił nawet popadnięciem w&nbsp;spore długi.
            Dopiero przedsiębiorczość i&nbsp;silny charakter Walerii
            spowodowały, że małżonkowie dorobili się majątku pod Błoniem
            i&nbsp;dwóch domów w Milanówku z&nbsp;wielkim ogrodem. Zachęciła
            męża do otwarcia przedsiębiorstwa perfumeryjnego i&nbsp;sklepu.
            Głową całego przedsięwzięcia była ona, choć firmował je Rufin, który
            był z wykształcenia chemikiem.
            <Gallery
              columnsNumber={2}
              isImgFit
              maxWidth="60"
              hoverColor="rgba(224, 144, 135, 0.4)"
              colorSign="#525252"
              images={[
                {src: img4, sign: 'Rufin Morozowicz'},
                {
                  src: img22,
                  sign: 'Rufin Morozowicz w&nbsp;scenicznej kreacji',
                },
              ]}
            />
            &emsp;&emsp;Teść Szczepkowskiego Rufin Morozowicz był niezwykle
            barwną postacią. Po ukończeniu szkoły Barszczewskiego podjął naukę
            na wydziale Chemicznym Szkoły Głównej Warszawskiej. Morozowicz
            opuścił w&nbsp;pewnym momencie Warszawę i&nbsp;wraz z&nbsp;zespołem
            aktorskim wyjechał do Płocka, gdzie stawiał pierwsze sceniczne
            kroki. Po jakimś czasie znalazł się w&nbsp;Krakowie, grając role
            komediowe czy śpiewając partię Janusza w&nbsp;Halce. Był także
            dyrektorem własnego teatru Morozowicz - Krotke, który prowadził
            jeszcze po powrocie do Warszawy. Grywał prawie zawsze repertuar
            komediowy, po pewnym czasie przechodząc do operetki warszawskiej
            jako aktor i&nbsp;reżyser. W&nbsp;roku 1890 otrzymał propozycję
            występów gościnnych w&nbsp;Cesarskich Teatrach w&nbsp;Petersburgu.
            Pomimo obietnicy wysokich zarobków nie skorzystał. Głośno mówiono
            także o&nbsp;jego rezygnacji ze stanowiska reżysera operetki, kiedy
            będąc obrażonym przez aktorkę Adolfinę Zimajer złożył swoją
            rezygnację. Aktorka zarzuciła mu przedkładanie produkcji win nad
            pracę reżyserką. Zarzut był prawdziwy, bo po wizycie w&nbsp;Wiedniu,
            gdzie był na prapremierze reżyserowanej przez siebie operetki Gejsza
            Sydneya Jonesa wpadł na pomysł produkcji własnego wina ze
            sprowadzanych z&nbsp;Krymu winogron. Pomysł okazał się jednak klapą,
            a&nbsp;dług 100 00 rubli spłacał niemal do końca życia. <br />
            <br />
            &emsp;&emsp;Nie zrażając się biznesowym niepowodzeniem, w&nbsp;kilka
            lat później na poddaszu budynku przy ul. Czerniakowskiej
            w&nbsp;Warszawie zaczął malować dekorację teatralne dla teatrów
            prowincjonalnych. Ale i&nbsp;tutaj pech go nie opuścił, bo
            nieuczciwy kontrahent naraził go na znaczne koszty, nie regulując
            rachunku. <br />
            <br />
            &emsp;&emsp;Finansowym strzałem w&nbsp;dziesiątkę było dopiero
            założenie laboratorium chemicznego i&nbsp;sklepu na spółkę
            z&nbsp;Brandlem. Przy dużym udziale swojej żony Walerii
            przedsięwzięcie zaczęło przynosić profity. Wspomina o&nbsp;tym
            w&nbsp;swoich wspomnieniach “Z&nbsp;lotu ptaka” jego córka Maria:
            „Na generalnej naradzie, kto w&nbsp;tym sklepie będzie sprzedawał
            […], mama postawiła sprawę jasno: - Oczywiście ja. Nie wiem jeszcze,
            jak to się robi, ale przy trafnej woli nauczę się. Ojciec bąknął pod
            nosem coś, co można było uważać za protest, ale mama energicznie się
            odcięła: - Brałeś tyle razy po skórze, bo nie dopuszczałeś mnie do
            niczego!”. Osobnym rozdziałem życiu Rufina było życie
            w&nbsp;Milanówku. Sukces finansowy sprawił, że Morozowicz miał
            fundusze na budowę willi Waleria. Kierował on sugestie
            architektoniczne oraz był inwestorem. Wzniesiona w&nbsp;1910 r.
            okazała się wymarzoną przystanią dla Morozowiczów.
            W&nbsp;dwupiętrowej formie budynek przetrwał jednak do roku 1915 r.
            gdy ostatnia kondygnacja budynku spłonęła od wybuchu szrapnela.
            Odbudowy w&nbsp;1920 r. ponownie podjął się Rufin Morozowicz,
            jednakże tym razem według jego projektu powstał budynek
            jednopiętrowy. <br />
            <br />
            &emsp;&emsp;Rufin Morozowicz zmarł w&nbsp;Milanówku 14 IV 1931 roku.
            Jego żona Waleria zmarła 21 VIII 1931 również w&nbsp;Milanówku.
            Oboje zostali pochowani w&nbsp;rodzinnym grobowcu na warszawskich
            Powązkach. Ich dom odziedziczyła córka Maria, a&nbsp;po jej śmierci
            “Waleria” stała się własnością jej córki Hanny Mickiewicz
            z&nbsp;domu Szczepkowskiej (1915 -2006).
          </SectionText>
        </Container>
      </ColorWrapper>
      <ColorWrapper bgColor={'#525252'} style={{paddingBottom: '2rem'}}>
        <Container>
          <Gallery
            columnsNumber={2}
            isImgFit
            maxWidth="60"
            hoverColor="rgba(224, 144, 135, 0.4)"
            colorSign="#fbfbfb"
            images={[
              {src: img5, sign: 'Maria wraz z&nbsp;córeczką Hanią'},
              {
                src: img6,
                sign: 'Hanna i&nbsp;Adam Mickiewiczowie',
              },
            ]}
          />
          <SectionText color={'#fbfbfb'}>
            &emsp;&emsp;Hanna urodziła się w&nbsp;Krakowie w&nbsp;okresie, kiedy
            Jan został przeniesiony do rezerwy z&nbsp;powodu ran, jakie poniósł
            na froncie. Hanna związała się z&nbsp;Milanówkiem na całe dorosłe
            życie. Po maturze podjęła naukę w&nbsp;prowadzonej przez ojca
            Miejskiej Szkole Sztuk Zdobniczych i&nbsp;Malarstwa. Posiadała duży
            talent w&nbsp;dziedzinie ceramiki. W&nbsp;okresie okupacji
            studiowała przez dwa lata medycynę. <br />
            <br />
            &emsp;&emsp;Po wybuchu wojny, w&nbsp;1940 r. włączyła się do
            konspiracji i&nbsp;już w&nbsp;następnym roku została łączniczką
            w&nbsp;oddziale do zadań specjalnych mjr. Mariana Drobika
            “Dzięcioła”, który początkowo był zastępcą, a&nbsp;następnie szefem
            Oddziału II Komendy Głównej Armii Krajowej. Szefem Biura od
            października 1942 r. był jej mąż Adam Rymwid Mickiewicz ps. “Konrad”
            (1905-1986). Hanna poznała Adama w&nbsp;iście filmowy sposób. Jak
            wspomina: Nasze matki – to było kilkanaście pań, urządziły lekcje
            tańca dla młodzieży i&nbsp;tam poznałam mojego męża... Siedziałyśmy
            na kanapie we cztery: Monika Żeromska, Marysia Skoczylasówna, Ela
            Ostryjanka i&nbsp;ja, na dużej sofie naprzeciwko drzwi
            w&nbsp;wielkim salonie warszawskim. W&nbsp;drzwiach stanął bardzo
            piękny mężczyzna, wysoki, szczupły, świetnie zbudowany. Rozejrzał
            się po sali, spojrzał na naszą kanapę, podszedł i&nbsp;powiedział do
            mnie: „Czy mogę panią prosić do walca?” Miałam wtedy czternaście
            lat. Tak zaczęła się moja znajomość z&nbsp;mężem. I&nbsp;całe życie
            już potem, do jego śmierci byliśmy razem. (...) Wyszłam za mąż jak
            miałam nieskończone osiemnaście lat. Od tego dnia, jak miałam lat
            czternaście na lekcjach tańca, codziennie po obiedzie [mój przyszły
            mąż] przychodził do nas do domu. I&nbsp;powiedział moim rodzicom,
            że: „Ja tu żadnego mężczyzny nie dopuszczę.”
            <br />
            <br />
            &emsp;&emsp;Dalekim przodkiem Adama Rymwida Mickiewicz był polski
            wieszcz narodowy Adam Mickiewicz (1798-1855). Pradziad Adama
            Rymwida, Aleksander Julian Rymwid-Mickiewicz h. Poraj (1801-1871)
            był rodzonym bratem narodowego poety Polski, Litwy i&nbsp;Białorusi.
            Ciekawostką jest fakt, że pierwszym mężem babki ze strony ojca Adama
            Rymwida Mickiewicza, Antoniny z&nbsp;domu Kościuszko (1830-1906) był
            dyktator powstania styczniowego Romuald Traugutt. Nazwisko
            Kościuszko również nie jest zbiegiem okoliczności, Antonina była
            bowiem wnuczką brata Tadeusza Kościuszki. <br />
            <br />
            &emsp;&emsp;Adam Rymwid Mickiewicz oraz kierowane przez niego Biuro
            Studiów Przemysłowych na podstawie przekazywanych od wywiadowców
            informacji przygotowywało meldunki z&nbsp;praktycznie całego obszaru
            Europy. Wysyłano je następnie do Sztabu Naczelnego Wodza
            znajdującego się w Londynie. Raporty dotyczyły głównie przemysłu
            zbrojeniowego III Rzeszy. Wśród nich poczesne miejsce zajmu­je jedno
            z&nbsp;największych osiągnięć polskiego wywiadu – rozpracowanie
            pro­dukcji niemieckich rakiet V1 i&nbsp;V2, dzięki czemu alianci
            mogli powstrzymać hitle­rowskie plany zniszczenia Anglii,
            bombardując w&nbsp;sierpniu 1943 r. Ośrodek Doświadczalny
            w&nbsp;Peenemuende.
            <br />
            <br />
            &emsp;&emsp;Po wybuchu Powstania Warszawskiego Hanna Mickiewiczowa
            zgłosiła się do służ­by sanitarnej.
            <br />
            <br />
            &emsp;&emsp;Lata po II&nbsp;wojnie światowej były ciężkim okresem
            dla Hanny i&nbsp;Adama. Z&nbsp;nocy z&nbsp;29 na 30 listopada 1950
            roku Adam został aresztowany i&nbsp;wyszedł na wolność dopiero
            w&nbsp;maju 1953 roku. Prokuratorem, który oskarżał Mickiewicza była
            Helena Wolińska. W&nbsp;1998 r. Ministerstwo Sprawiedliwości
            Rzeczypospolitej Polskiej wystosowało do władz brytyjskich wniosek
            o&nbsp;ekstradycję Wolińskiej, zarzucając jej bezprawne pozbawienie
            wolności w&nbsp;latach 1950–1953 24 żołnierzy Armii Krajowej,
            w&nbsp;tym gen. bryg. Augusta Emila Fieldorfa „Nila” . 21 listopada
            1950 wydała postanowienie o&nbsp;tymczasowym aresztowaniu Fieldorfa
            i&nbsp;nadzorowała prowadzone przeciwko niemu śledztwo,
            w&nbsp;konsekwencji którego “Nil” został stracony. <br />
            <br />
            &emsp;&emsp;W&nbsp;trakcie pobytu w&nbsp;więzieniu Adam Mickiewicz
            podupadł na zdrowiu i&nbsp;borykał się z&nbsp;problemami zdrowotnymi
            do końca życia. Hanna Mickiewicz, która w&nbsp;czasie przeszukania
            bezpieki nosiła zmikrofilmowane meldunki w&nbsp;specjalnie uszytym
            pasie, dopiero w&nbsp;listopadzie 1991 roku postanowiła się je
            ujawnić. Przez wiele lat Hanna Mickiewiczowa brała czynny udział
            w&nbsp;działalności śro­dowisk żołnierzy Armii Krajowej -
            w&nbsp;Zgrupowaniu Żołnierzy Batalionu „Zaremba-Piorun” oraz
            Światowym Związku Żołnierzy AK. Była wielolet­nim członkiem Komisji
            Socjalnej Okrę­gu Warszawskiego Związku. Kilka tygodni przed
            śmiercią została odznaczona Krzyżem Oficerskim Orde­ru Odrodzenia
            Polski. Wcześniej była też uhono­rowana m.in. Brązowym Krzyżem
            Zasługi z&nbsp;Mieczami, Krzyżem Armii Kra­jowej, Odznaką „Akcja
            Burza” i&nbsp;Krzy­żem Kawalerskim Orderu Odrodzenia. Polski.
            Otrzymała również odznakę „Zasłużony dla m.st. Warszawy”. Przez
            wiele lat współpracowała z&nbsp;Tow. Miłośników Milanówka
            w&nbsp;upowszechnianiu wiedzy na temat walki mieszkańców naszego
            miasta z&nbsp;hitlerowskim okupantem oraz wspomnień dotyczących
            Powstania Warszawskiego i&nbsp;działalności Biura Studiów
            Przemysłowych Oddziału&nbsp;II&nbsp;KG&nbsp;AK.
            <br />
            <br />
            <br />
            &emsp;&emsp;Od roku 1978 roku w&nbsp;willi Waleria prowadziła
            prywatne muzeum Jana Szczepkowskiego. W&nbsp;roku 2007 miasto
            Milanówek kupiło wille Waleria razem z&nbsp;pokaźnym zbiorem
            kilkuset eksponatów z&nbsp;twórczości Jana Szczepkowskiego.
            Pozostała część kolekcji została w&nbsp;prowadzonej przez wnuczkę
            Szczepkowskich Ewę Mickiewicz Fundacji im. Marii i&nbsp;Jana
            Szczepkowskich, której zadaniem jest ochrona i&nbsp;promocja
            twórczości artystycznej Jana Szczepkowskiego oraz twórczości
            literackiej Marii Szczepkowskiej.
          </SectionText>
        </Container>
        <Container>
          <p
            style={{
              fontFamily: 'LATOREGULAR',
              fontSize: '10px',
              lineHeight: 1.2,
              textAlign: 'justify',
              color: '#ffffff',
              marginTop: '2rem',
            }}>
            Źródła informacji:
            <br />
            <br />
            Bartoszek Monika, Jan Szczepkowski, Centrum Rzeźby Polskiej w
            Orońsku, Orońsko, 2014
            <br />
            <br />
            Chrudzimska – Uhera Katarzyna, JAN SZCZEPKOWSKI, ŻYCIE I TWÓRCZOŚĆ,
            Urząd Miejski w Milanówku, 2008 r.
            <br />
            <br />
            Chrudzimska-Uhera Katarzyna, Małżeństwo z rozsądku? : czyli o
            artystycznych dylematach i kompromisach w czasach władzy ludowej, w
            oparciu o działalność społeczną, wypowiedzi i projekty rzeźbiarskie
            Jana Szczepkowskiego z lat 1945-1964, Saeculum Christianum : pismo
            historyczne
            <br />
            <br />
            Chrudzimska-Uhera Katarzyna, O rzeźbiarzu (nieco) zapomnianym,
            Niepodległość i Pamięć 17/1 (31), 257-271, 2010
            <br />
            <br />
            Chrudzimska-Uhera Katarzyna, Prof. Jan Szczepkowski i jego dzieło
            (1878-1964), Milanówek Miejsce Magiczne pod red. Andrzeja Pettyna,
            Towarzystwo Miłośników Milanówka, 2005
            <br />
            <br />
            Koszuta Mariusz, Spacer z Walerią po letnisku Milanówek, Wydawnictwo
            Impress, Warszawa 2010 r.
            <br />
            <br />
            Morozowicz – Szczepkowska Maria, Z lotu ptaka, Państwowy Instytut
            Wydawniczy, Warszawa, 1968
            <br />
            <br />
            Szczepkowski Jan, Wypukłe i wklęsłe. Wspomnienia, Wstęp, opracowanie
            i komentarz Katarzyna Chrudzimska - Uhera, Urząd Miasta Milanówka,
            Milanówek, 2010
          </p>
          <a
            style={{
              fontFamily: 'LATOREGULAR',
              fontSize: '10px',
              lineHeight: 1.2,
              textAlign: 'justify',
              color: '#ffffff',
              marginTop: '10px',
            }}
            href={
              'https://www.1944.pl/archiwum-historii-mowionej/hanna-barbara-szczepkowska-mickiewicz,788.html'
            }>
            https://www.1944.pl/archiwum-historii-mowionej/hanna-barbara-szczepkowska-mickiewicz,788.html
          </a>
          <br />

          <a
            style={{
              fontFamily: 'LATOREGULAR',
              fontSize: '10px',
              lineHeight: 1.2,
              textAlign: 'justify',
              color: '#ffffff',
            }}
            href={
              'https://www.encyklopediateatru.pl/autorzy/788/maria-morozowicz-szczepkowska'
            }>
            https://www.encyklopediateatru.pl/autorzy/788/maria-morozowicz-szczepkowska
          </a>
          <br />
          <a
            style={{
              fontFamily: 'LATOREGULAR',
              fontSize: '10px',
              lineHeight: 1.2,
              textAlign: 'justify',
              color: '#ffffff',
            }}
            href={'http://www.tmm.net.pl/print,news,752.html#_ftn2'}>
            http://www.tmm.net.pl/print,news,752.html#_ftn2
          </a>
        </Container>
      </ColorWrapper>
      <ColorWrapper bgColor={'#e09087'}>
        <Container>
          <Gallery
            columnsNumber={4}
            isImgFit
            hoverColor="rgba(224, 144, 135, 0.4)"
            style={{gridGap: '3rem 1rem'}}
            colorSign="#525252"
            images={[
              {
                src: img7,
                sign: 'Chwila odpoczynku. Waleria i Rufin razem z córką Marią',
              },
              {
                src: img8,
                sign: 'Jan Szczepkowski z żoną Marią oraz jej matką Walerią',
              },
              {
                src: img9,
                sign:
                  'Rodzinna scena. Małżeństwo Szczepkowskich i Waleria Morozowicz.',
              },
              {
                src: img11,
                sign: 'Waleria Morozowicz na tle willi Ewarystówka',
              },
              {
                src: img12,
                sign: 'Maria Morozowicz w trakcie teatralnej kreacji',
              },
              {
                src: img13,
                sign:
                  'Maria Morozowicz (siedzi pierwsza z lewej) w towarzystwie środowiska artystycznego WIlna przełomu XIX i XX wieku.',
              },
              {
                src: img14,
                sign: 'Maria Morozowicz',
              },
              {
                src: img15,
                sign:
                  'Jan Szczepkowski na tle Świątynia Hery w Agrigento na Sycylii, 1937r.',
              },
              {
                src: img16,
                sign:
                  "Maria Szczepkowska na wakacjach we Włoszech w 1937 r. Na odwrocie opis 'Rzym Colloseum'",
              },
              {
                src: img17,
                sign:
                  'Maria Szczepkowska na tle panoramy Taorminy, Sycylia, 1937 r.',
              },
              {
                src: img18,
                sign:
                  'Wakacje na Sycyli w 1937 r. Z tyłu zdjęcia opis Nasz niedoszły domek w Taorminie na Sycylii',
              },
              {
                src: img19,
                sign:
                  'Maria Szczepkowska na tle Świątyni Zgody w Agrigento na Sycylii, 1937 r.',
              },
              {
                src: img20,
                sign:
                  'Jubileusz pięćdziesięciolecia pracy scenicznej Rufina Morozowicza. Teatr Nowości, 12 marca 1925 r.',
              },
              {
                src: img21,
                sign: 'Rufin Morozowicz w otoczeniu ogrodu',
              },

              {
                src: img23,
                sign: 'Rufin Morozowicz w scenicznej kreacji',
              },
              {
                src: img24,
                sign: 'Rufin Morozowicz w scenicznej kreacji',
              },
              {
                src: img28,
                sign:
                  'Zaświadczenie o przyznaniu Krzyża Srebrnego Orderu Virtuti Militari dla Adama Mickiewcza',
              },
              {
                src: img26,
                sign: 'Róża Kotowicz, matka Walerii',
              },
              {
                src: img27,
                sign:
                  'Jerzy Morozowicz, zmarły przedwcześnie syn Rufina i Walerii',
              },
              {
                src: img25,
                sign:
                  'Rufin Morozowicz w trakcie spaceru po Letnisku Milanówek',
              },
              {
                src: img29,
                sign: 'Laboratorium firmy Perfection w podwórzu po lewo',
              },
              {
                src: img30,
                sign: 'Maria Morozowiczówna po skończeniu pensji',
              },
              {
                src: img31,
                sign:
                  'Nad morzem w Jastarnii. Od lewej Maria Skoczylas, Monika Żeromska, Hanna Szczepkowska',
              },
              {
                src: img32,
                sign:
                  'Rufin Morozowicz jako Menelaus w Pięnej Helenie Offenbacha',
              },
            ]}
          />
        </Container>
      </ColorWrapper>
    </PageContainer>
  );
};

export default Family;
